@use 'sass:color';

h1 {
  margin: 0.67em 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $text-gray;
}

dl {
  margin-top: 0;
  margin-bottom: 0;

  & + & {
    margin-top: 1rem;
  }

  dt {
    font-weight: $f-light-bold;
    font-size: 14px;
  }
  dd {
    pre {
      margin: 0;
    }
  }
}

.text-label {
  color: $brand-color-green !important;
}

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.5;
  color: #000;
  vertical-align: middle;
  background-color: transparent;
  background-image: none;
  border: 1px solid #dddddd;
  border-radius: 2px;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  cursor: not-allowed;
  background-color: #eeeeee;
  padding-left: 5px;
}

textarea.form-control {
  height: 30px;
  box-shadow: none !important;
  overflow: hidden;
  resize: none;
}

//FORMS
.form-control {
  border-radius: 0px;
  border: 0px;
  border-bottom: 1px solid #edeff0;
  padding-left: 0;
  appearance: none;
}

.form-group {
  position: relative;
  padding: 5px 0px;

  .chars-counter-container {
    display: none;
    cursor: default;
  }

  // we want it to always take parents width, parent decides on
  // available select2 width
  .select2.select2-container {
    max-width: 100%;
  }
}

.input-group-horizontal {
  .form-group {
    flex-grow: 1;
    width: 50%;
  }
}

.form-group-visual {
  position: relative;
  border: 1px solid #e3e4e6 !important;
  padding: 10px;
  border-radius: 5px;
  background-color: #f8f8f9;
  margin: 5px 0;

  &:empty {
    display: none;
  }

  .form-control:focus {
    background-color: #f8f8f9;
  }
}

.form-control {
  &:focus + .chars-counter-container {
    display: block;
  }
}

// fixes bootstrap float:left bullshit
.form-check {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 0.25rem;

  .form-check-input,
  input[type='checkbox'] {
    flex-shrink: 0;
    flex-grow: 0;
    float: none;
    margin: 0;
    vertical-align: initial;
  }
}

input[type='checkbox'],
input[type='radio'] {
  cursor: pointer;
}

input[type='date'] {
  outline: none;
  border: none;
  border-bottom: 1px solid $secondary;
  height: 34px;
  color: #555;
  position: relative;

  &::-webkit-calendar-picker-indicator {
    background-position: calc(100% - 16px) center;
    background-size: auto;
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

.btn {
  font-family: Roboto, Arial, sans-serif;
  --bs-btn-border-width: 0;
  --bs-btn-box-shadow: none;
  --bs-btn-line-height: 1em;
  --bs-btn-font-weight: #{$f-thin};
  white-space: nowrap;
  letter-spacing: 0.032em;

  &:not(.btn-sm) {
    --bs-btn-font-size: 15px;
    --bs-btn-padding-y: 10px;
    --bs-btn-border-radius: 4px;
  }
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn,
button[disabled] {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.5;
  box-shadow: none;
  --bs-btn-disabled-color: var(--bs-white);
}

.btn-primary {
  --bs-btn-color: var(--bs-white);
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-active-color: var(--bs-white);
  --bs-btn-disabled-bg: #ababab;
}

.btn-secondary {
  &[disabled] {
    --bs-btn-disabled-color: black;
  }
}

.btn-entry {
  --bs-btn-color: #4b986e;
  --bs-btn-bg: #edf5f1;
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-active-color: var(--bs-white);
  --bs-btn-hover-bg: var(--bs-green);
  --bs-btn-active-bg: var(--bs-primary);
}

.btn-warning {
  --bs-btn-color: var(--bs-white);
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-active-color: var(--bs-white);
  --bs-btn-disabled-bg: #f0ad4e;
  --bs-btn-active-bg: #ed9c28;
}

.btn-danger {
  --bs-btn-color: var(--bs-white);
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-active-color: var(--bs-white);
  --bs-btn-disabled-bg: #d9534f;
  --bs-btn-active-bg: #{$remove-red};
}
.text-danger {
  --bs-danger-rgb: #{color.red($remove-red) + ',' + color.green($remove-red) + ',' +
    color.blue($remove-red)};
}

.btn-success {
  --bs-btn-color: var(--bs-white);
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-active-color: var(--bs-white);
  --bs-btn-disabled-bg: #5cb85c;
  --bs-btn-active-bg: #47a447;
}

.btn-info {
  --bs-btn-color: var(--bs-white);
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-active-color: var(--bs-white);
  --bs-btn-disabled-bg: #5bc0de;
  --bs-btn-active-bg: #39b3d7;
}

.btn-create {
  transform: scale(1);
  animation: pulse 1s infinite;
  animation-iteration-count: 1;
  animation-delay: 2s;

  --bs-btn-bg: #5cb85c;
  --bs-btn-color: var(--bs-white);
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-active-color: var(--bs-white);
  --bs-btn-active-bg: #5cb85c;
  --bs-btn-hover-bg: #47a447;

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(#5cb85c, 0.7);
    }

    70% {
      box-shadow: 0 0 0 10px transparent;
    }

    100% {
      box-shadow: 0 0 0 0 transparent;
    }
  }
}

.page-buttons {
  min-height: $actions-bar-height;
  background-color: rgba(white, 0.8);
  backdrop-filter: blur(4px);
  border-top: 1px solid #edeff0;

  padding-left: calc(#{$one-col-padding} + var(--page-buttons-left-space));
  padding-right: $one-col-padding;

  display: flex;
  gap: 1rem;
  align-items: center;

  // buttons go to the right
  justify-content: flex-end;

  // or, put them into .page-buttons-left, .page-buttons-right
  // so some go to the left some to right.
  &:has(&-left, &-right) {
    justify-content: space-between;
  }

  &-left,
  &-right {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
}

// growing textarea
.textarea-grow-wrap {
  display: grid;
  overflow: hidden;

  &:before {
    content: attr(data-value) ' ';
    white-space: pre-wrap;
    visibility: hidden;
    // match textarea.form-control styles
    font-weight: $f-normal;
    line-height: 1.5;
  }

  & > textarea.grow {
    height: unset;
    resize: none;
  }

  & > textarea.grow,
  &:before {
    grid-area: 1 / 1 / 2 / 2;
    // match textarea.form-control styles
    border-width: 1px;
    padding: 6px 12px;
    padding-left: 0;
  }
}

// bring intercom widget higher up so it doesn't cover the buttons
// need to override their inline style
.intercom-lightweight-app-launcher {
  bottom: 10px + $actions-bar-height !important;
}

.button-panel {
  padding: 10px 0 0 0;
  text-align: right;

  .btn-primary {
    line-height: 1em;
    font-size: 15px;
    margin: 0;

    &.full-w {
      width: 100%;
    }
  }
  .cancel {
    cursor: pointer;
    margin: 0 0 0 6px;
  }
}

.btn-file {
  position: relative;
  overflow: hidden;
}
.btn-file input[type='file'] {
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  opacity: 0;
  outline: none;
  background: transparent;
  cursor: inherit;
}

.dropdown-item {
  cursor: pointer;
  line-height: 2;

  &.active,
  &:active {
    background-image: linear-gradient(to bottom, #99dbad 0%, $brand-color 100%);
  }

  &:hover,
  &:focus {
    background-image: linear-gradient(to bottom, #f5f5f5 0%, #e8e8e8 100%);
  }

  &::selection {
    color: $dropdown-link-color;
    background: transparent;
  }
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-block;
  padding: 5px;
}

.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  background-color: #eeeeee;
  border-color: #01439f;
}

.alert {
  font-size: 15px;
  letter-spacing: 0.032em;
}

.modal .modal-content {
  box-shadow:
    0px 2px 5px rgba(0, 0, 0, 0.2),
    0px 5px 15px rgba(0, 0, 0, 0.1);
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.hidden {
  display: none !important;
  visibility: hidden !important;
}

.min-width-50-percent {
  min-width: 50%;
}
