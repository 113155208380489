.approve-button {
}

.delete-button {
}

.f-table-box table tr td {
  &.btn-line-small,
  &.btn-line-bigger {
    width: 310px;
  }
}

.user-buttons-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.25rem;
}
