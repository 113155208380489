.expense-form-header {
  position: sticky;
  z-index: 1;
  top: 0;

  &:before {
    @include cover-overscroll-pseudo($z-index: -1);
  }

  .section-header {
    margin-top: -5px; // overscroll rounded corners
  }
}

.s-transaction {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 5px 8px;
  background-color: #edeff0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  .back-btn {
    display: none;
  }
}

.summary {
  cursor: default;
  color: dimgray;
  font-size: 13px;
}

.free-meal-days {
  border: 1px solid #ddd;
  border-bottom: none;
  padding-bottom: 10px;
}

.free-meal-day {
  border-bottom: 1px solid $secondary !important;
  color: dimgray;
  cursor: default;

  display: flex;
  justify-content: space-between;
  font-size: 13px;
}

.free-meal-day-group {
  @include text_overflow;

  .free-meal-label {
    color: black;
    font-weight: $f-normal;
    padding: 0 4px;
  }

  .form-check-label {
    @include text_overflow;
    width: 100%;
  }
}

.free-meal-days-header {
  font-size: 13px;
  color: $brand-color-green;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  // compensate asymetric parent paddings
  margin-bottom: 4px;

  > h5 {
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
    color: inherit;
    margin: 0;

    flex-grow: 1;
    @include text_overflow;
  }

  .toggle-free-meal-days {
    cursor: pointer;
  }

  .form-check-label {
    @include text_overflow;
  }
}

.start_time,
.end_time,
.paid-at {
  padding-left: 10px;
}

.toggle-icon {
  &:before {
    content: '\25be'; // ▾
  }
  color: $brand-color-green;
}

.vertically-flipped {
  transform: scaleY(-1);
}

.datepicker {
  cursor: pointer;
}

.expense-settings-menu {
  font-size: inherit;
}

.billed-amount {
  color: #000;
}

.pdf-processing {
  border-radius: 0 0 5px 5px;
  background: no-repeat center url('~images/pdf-processing.svg');
  background-size: 90%;

  position: relative;
  container-type: size;
  margin: auto;
  width: 50%;
  aspect-ratio: 1;

  &:before {
    // if background-size of the parent changes — these has to change as well
    font-size: 4.75cqh;
    left: 21cqw;
    bottom: 15cqh;

    position: absolute;
    content: attr(data-caption);
    text-transform: lowercase;
    color: #cad3d3; // same as middle shadow in the svg
    font-style: italic;
    font-weight: $f-light-bold;
  }
}

.documents-form-container {
  &.f-box {
    box-shadow: $bottom-box-shadow;
  }

  .documents,
  .tax-card {
    &.section-body {
      margin: -10px;
      padding: 15px 10px;
      background-color: white;
    }
  }

  .tax-card {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    .tax-card-img {
      max-width: 100%;
      max-height: 375px;

      border-radius: 4px;
      min-height: 32px;
      @include preloader-background(16px 16px);
    }
  }

  .documents.section-body {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .documents-list {
      width: 100%;
      &:empty {
        display: none;
      }
    }

    > table td {
      padding: 2px 3px;
    }
  }
}

// we want sticky headers for `documents-form-container`
// only in 3rd column of 3col layout
#image-container {
  .documents-form-container {
    &:before {
      @include cover-overscroll-pseudo($width: calc(100% + 20px));
      margin-left: -10px;
    }
    .section-header {
      position: sticky;
      top: 10px;
      margin-top: -5px; // overscroll rounded corners
    }
  }
}

.chars-counter-container {
  position: absolute;
  top: 10px;
  right: 10px;
}

.daily-allowance-entry,
.paid-at-form {
  .calendar-toggle {
    border: 0;
    border-bottom: 1px solid #dddddd;
    color: #696969;
    cursor: pointer;
  }
}

.daily-allowance-form {
  .section-header {
    text-align: center;
  }
}

.trip-form {
  .section-header {
    text-align: center;
  }
}

.mileage-entry {
  position: relative;

  .route,
  .address {
    text-overflow: ellipsis;

    &.is-invalid {
      background-position: right 2rem center;
    }
  }

  .toggle-route,
  .remove-waypoint,
  .remove-entry {
    position: absolute;
    right: 0.5rem;
  }
  .toggle-route {
    top: 0;
  }
  .remove-waypoint {
    top: 27px;
  }
  .remove-entry {
    top: 27px;
  }

  .full-route {
    position: relative;

    .toggle-route {
      &[disabled] {
        pointer-events: none;
        opacity: 0.5;
      }
    }

    // match toggle route icon position
    .route {
      &.is-invalid {
        background-position-y: 8px;
      }
    }
  }

  .waypoint-group {
    position: relative;
  }

  .number_of_people {
    border-left: 1px solid $secondary;
    padding-left: 5px;
  }
}

.remove-cost-button {
  width: 100%;
  margin-top: 10px;
}

#trip-form-entry-0 {
  .remove-entry {
    display: none;
  }
}

.date-border {
  border-right: 2px solid $secondary;
}

.trip-maps {
  height: 300px;
}

// highlight select2 input when validation error
select.is-invalid + .select2 .select2-selection {
  border-color: #e85062;
}

.vat-line-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  #transaction-form-vat-line-0 & {
    display: none;
  }
  .action-btn {
    display: flex;
    align-items: center;
    font-size: 14px;
    position: relative;
    padding: 5px 0px;
    background-color: transparent;
    border: 0;
    font-weight: 500;
    margin-left: 20px;

    &:hover {
      opacity: 0.7;
    }
    .action-icon {
      width: 16px;
      margin-left: 10px;
    }
    &.split-cost,
    &.remove-split-cost {
      color: $brand-color-green;
    }
  }
}

.expense-comment {
  background-color: lightyellow;
  padding: 0.75rem 1rem;
  border-radius: 5px;
  display: flex;
  gap: 0.5rem 0.25rem;
  flex-wrap: wrap;

  font-size: 13px;
  font-weight: $f-normal;

  strong {
    font-weight: $f-light-bold;
  }

  &-body {
    flex: 1 0 100%;
    padding-left: 0.5rem;
  }
}

.helper-icon {
  color: grey;
  margin-left: 0.5rem;

  &:hover {
    color: $brand-color-green;
  }
}
