@use 'sass:math';

.integrations {
  $gutter: 1.5rem;
  padding: ($gutter * 1.5) $gutter;

  .logo {
    min-width: 140px;
    height: 70px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    // see customer_ui/models/integration/model.coffee:22 for source of names
    $logo-names: 'net_suite', 'finvoice', 'netvisor', 'procountor',
      'spreadsheet', 'digia_erp', 'em_ce', 'fortnox', 'eurocard',
      'nordea_first_card', 'danske_mastercard', 'pliant', 'zevoy', 'sepa',
      'mepco', 'severa';

    @each $name in $logo-names {
      &.#{$name} {
        background-image: url('~images/integrations/logos/#{$name}.png');
      }
    }
  }

  .description-card {
    $padding: 25px;
    $rafiki-width: 225px;
    $rafiki-padding: calc(2 * #{$gutter} + #{$rafiki-width});

    min-height: 240px;
    background: #f7e0c1;
    border-radius: 20px;
    position: relative;
    padding: $padding;
    padding-left: $rafiki-padding;
    z-index: 1;

    // rafiki juggles
    &:before {
      background-image: url('~images/integrations/rafiki.svg');
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      content: ' ';
      position: absolute;

      aspect-ratio: math.div(828, 992);
      width: $rafiki-width;

      left: $padding;
      bottom: 0;
      z-index: -1;
    }

    // integration logos, two rows
    .logos {
      position: absolute;

      height: 200px;
      top: calc(50% - 100px);
      right: $padding;
      z-index: -1;

      display: grid;
      grid-template-columns: repeat(9, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-template-areas:
        '   . row1 row1 row1 row1 row1 row1 row1 row1'
        'row2 row2 row2 row2 row2 row2 row2 row2 .';

      .top-row {
        grid-area: row1;
      }
      .bottom-row {
        grid-area: row2;
      }
      .top-row,
      .bottom-row {
        display: flex;
        gap: 1em;
        align-items: center;
        justify-content: flex-end;
      }

      em {
        display: flex;
        align-items: center;
        justify-content: center;

        background-color: rgba(white, 66%);
        border-radius: 10px;
        width: 150px;
        height: 70px;
        flex-shrink: 0;

        > .logo {
          min-width: 130px;
          height: 30px;

          &.mepco {
            height: 25px;
          }
          &.em_ce {
            height: 50px;
          }
          &.netvisor {
            height: 20px;
          }
          &.zevoy {
            height: 40px;
          }
        }
      }
    }

    h2,
    h4 {
      color: #222427;
    }

    p {
      margin: 0;
      font-weight: $f-normal;
      color: #535353;
    }

    .list-nav-tabs {
      list-style: none;
      margin-top: 10px;
      padding-left: 20px;

      li::before {
        content: '\2022';
        color: #307275;
        font-weight: $f-bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }

      a {
        color: #307275;
        font-weight: $f-normal;
        text-decoration: none;

        &[aria-selected],
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .integration-groups-nav-tabs {
    margin-bottom: 20px;

    .nav-link {
      border: none;
      color: #535353;
      font-weight: $f-normal;
      font-size: 16px;
      line-height: 19px;
      text-transform: capitalize;
      padding-top: 30px;
      padding-bottom: 30px;

      &[aria-selected],
      &:hover {
        color: #222427;
        border-bottom: 3px solid #307275;
        background-color: transparent;
      }
    }
  }

  .integration-item {
    margin-bottom: 20px;
    background: #ffffff;
    border-radius: 10px;

    .info-card {
      min-height: 120px;
      padding: 25px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .about {
        padding-left: 20px;
        flex-grow: 2;
        max-width: 820px;
        margin-right: auto;

        p {
          color: #272937;
          font-weight: $f-normal;
          opacity: 50%;
        }
      }

      .edit-settings {
        background: transparent;
        border: none;
        color: #535353;

        &:hover {
          color: #307275;
        }
      }
    }

    .integration-settings-form {
      border-top: 1px solid $expense-border-color;
      padding: 25px 20px;

      label {
        color: $brand-color-green;
        font-weight: $f-thin;
      }
    }
  }

  @media (max-width: 1430px) {
    .description-card {
      .logos {
        right: auto;
        overflow: hidden;
        // width of text and a bit of liquid padding
        padding-left: calc(330px + 5%);

        .top-row,
        .bottom-row {
          justify-content: flex-start;
        }
      }
    }
  }

  @media (max-width: 930px) {
    .description-card {
      .logos {
        display: none;
      }
    }
  }

  @media (max-width: 600px) {
    .description-card {
      padding-left: $gutter;

      &:before {
        left: auto;
        right: 0;
        height: 55%;
      }
    }

    .info-card .logo {
      display: none;
    }
  }

  @media (max-width: 480px) {
    .description-card {
      &:before {
        display: none;
      }
    }
  }
}
